@import './theme';
@import '~vizzuality-components/dist/bundle';

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  padding-left: 20px;
}

// .mapboxgl-ctrl-bottom-right {
//   right: 80px;
// }

.mapboxgl-ctrl-attrib-inner > a {
  font-size: 10px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background: transparent;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: $primaryFontFamily !important;
}

.MuiFormControlLabel-root {
  margin: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: var(--primary-font-family) !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  // background-color: #c8def4;
}

.theme-light,
.theme-dark {
  background-color: $backgroundColor;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $primaryColor !important;
}

:focus {
  outline: none !important;
}

.planet-links {
  color: $primaryColor;
}

.planet-links:hover {
  color: $primaryColor;
}

.planet-links:visited {
  color: $primaryColor;
}

:export {
  primaryColor: $primaryColor;
  primaryFontColor: $primaryFontColor;
}

a {
  text-decoration: none;
}

// Reseting User agent styles to maintain cross browser interferences
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

// Deforestation slider
.rc-slider-mark-text {
  font-family: $primaryFontFamily;
  color: $primaryFontColor;
  font-size: 12px;
}

// Stepper Connector
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: $primaryColor !important;
}

.comparison-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mapboxgl-compare {
  top: 0;
}

.mapboxgl-compare .compare-swiper-vertical {
  background-color: $primaryColor;
}

// .mapboxgl-ctrl-bottom-right {
//   margin: 0 10px 40px 0 !important;
// }

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib-inner {
  display: none;
}

@font-face {
  font-family: 'Pampers Script';
  src: url('/tenants/pampers/Pampers Script.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HarmoniaSansPro';
  src: url('/tenants/pampers/HarmoniaSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.primaryButton,
.secondaryButton {
  color: $light;
  font-weight: bold;
  background-color: $primaryColor;
  background-image: linear-gradient(
    103deg,
    $primaryColor 5%,
    $primaryDarkColor 116%
  );
  height: 40px;
  padding: 0px;
  text-align: center;
  border: 0px;
  border-radius: 52px;
  min-width: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none;
}
.primaryButton:hover,
.secondaryButton:hover {
  transform: translateY(-7px);
  cursor: pointer;
}

.secondaryButton {
  min-width: 240px;
  background-image: none;
  background-color: $blueishGrey;
  color: $primaryFontColor;
  p {
    margin-left: 6px;
  }
  svg {
    height: 12px;
  }
}

button {
  border: 0px;
  padding: 0px;
  margin: 0px;
  background: transparent;
}

@font-face {
  font-family: 'HarmoniaSansPro';
  src: url('/tenants/pampers/HarmoniaSansPro-SemiBd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.6);
}

.notificationContainer {
  position: fixed;
  bottom: 50px;
  right: 70px;
  min-width: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  max-width: 98%;
  pointer-events: none;
  z-index: 999;
  height: fit-content;
  background: transparent;
}

@include xsPhoneView {
  .notificationContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 100px;
    max-width: 100%;
    border-radius: 0px;
  }
}

.read-more-button {
  font-weight: bold;
  cursor: pointer;
}
