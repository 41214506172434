@import './../../../theme/theme';

.cardContainer {
  background-color: $backgroundColor;
  border-radius: 10px;
  width: 100%;
  max-width: 412px;
  z-index: 10;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
}

.header {
  display: flex;
  flex-direction: column;
  background-color: $primaryColor;
  width: 100%;
  border-radius: 10px 10px 60px 60px;
  padding: 20px;
}

.headerCloseIcon,
.headerBackIcon {
  height: 32px;
  width: 32px;
  min-width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerCloseIcon:hover,
.headerBackIcon:hover {
  cursor: pointer;
}

.headerTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.headerTitle {
  font-family: $primaryFontFamily;
  font-weight: bolder;
  font-size: 27px;
  color: $light;
}

.plantProjectName {
  font-family: $primaryFontFamily;
  font-size: 12px;
  color: $light;
  width: 100%;
  margin-top: 4px;
}

.currencyRate {
  display: flex;
  flex-direction: row;
  color: $light;
  align-items: baseline;
}

.currencyRateDisabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.currencyRate:hover {
  cursor: pointer;
}

.currency {
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: 12px;
}

.currencyDisabled {
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: 12px;
  color: $highlightBackground;
}

.downArrow {
  margin-left: 5px;
}

.rate {
  margin-left: 9px;
  font-family: $primaryFontFamily;
  font-size: 12px;
}

.treeDonationContainer {
  padding: 26px;
  padding-top: 0px;
}

.isGiftDonation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.isGiftDonationText {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $highlightBackground;
  cursor: pointer;
}

.selectTreeCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.treeCountOption,
.treeCountOptionSelected {
  height: 54px;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 30%;
  margin-top: 20px;
}
.treeCountOption {
  border: 1px solid $dividerColor;
}

.treeCountOptionSelected {
  border: 1px solid $primaryColor;
}
.treeCountOption:hover,
.treeCountOptionSelected:hover {
  cursor: pointer;
  border: 2px solid $primaryColor;
}
.treeCountOption > .treeCountOptionTrees {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  padding-right: 10px;
}

.treeCountOptionSelected > .treeCountOptionTrees {
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: $fontSubTitleSize;
  color: $primaryColor;
  padding-right: 10px;
}

.customTreeInput {
  border: 0px;
  border-bottom: 1px solid $primaryFontColor;
  margin: 0px 10px;
  width: 70%;
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: $fontSubTitleSize;
  color: $primaryColor;
  text-align: center;
  background-color: transparent;
  outline: 0px;
}

.treeCountOption > .customTreeInput:focus {
  display: block;
  width: 70%;
  outline: none;
}

.treeCountOptionSelected > .customTreeInput {
  border-bottom: 1px solid $primaryColor;
}

/* Tax Deductible */
.isTaxDeductible {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  flex-flow: wrap;
}

.isTaxDeductibleText {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  white-space: wrap;
  margin-right: 6px;
}

.taxDeductible {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taxDeductibleDisabled {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taxDeductible:hover {
  cursor: pointer;
}

.taxDeductibleCountry {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryColor;
  white-space: nowrap;
}

.taxDeductibleCountryDisabled {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $highlightBackground;
}

.downArrow {
  margin-left: 5px;
}

.finalTreeCount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.totalCost {
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: 16px;
  color: $primaryColor;
  margin-left: 4px;
}

.totalCostText {
  font-family: $primaryFontFamily;
  font-weight: bold;
  font-size: 16px;
  color: $primaryFontColor;
  margin-left: 6px;
}
.totalCostText > p{
  width: 100%;
  text-align: center;
}

.actionButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.actionButtonsContainerCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.actionButtonsText {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  margin-left: 24px;
  margin-right: 24px;
}
.continueButton {
  background-image: linear-gradient(103deg, $primaryColor 5%, $primaryDarkColor 116%);
  border-radius: 10px;
  padding: 8px 40px;
  min-height: 40px;
  color: $light;
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.continueButtonDisabled {
  background-color: $dividerColor;
  border-radius: 10px;
  padding: 8px 40px;
  color: $light;
  width: 100%;
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  font-weight: 600;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.paymentRequestPlaceholder {
  background-color: $blueishGrey;
  border-radius: 6px;
  padding: 8px 40px;
  color: $dark;
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  font-weight: 600;
}

.horizontalLine {
  height: 1px;
  background-color: $dividerColor;
  margin-top: 20px;
}

/* Gift Trees Styles */

.singleGiftTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.singleGiftTitle {
  font-family: $primaryFontFamily;
  font-size: 14px;
  color: $primaryFontColor;
  margin-top: 10px;
}
.singleGiftTitle > span {
  font-weight: 600;
}

.singleGiftRemove {
  cursor: pointer;
  color: $dangerColor;
  font-size: 12px;
  margin-top: 20px;
}

.formRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}
.formRowInput{
  flex: 1;
}

/* Payment error */
.paymentError {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $dangerColor;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
}

.formErrors {
  font-family: $primaryFontFamily;
  font-size: 12px;
  color: $dangerColor;
  margin-left: 6px;
}

:export {
  primaryFontColor: $primaryFontColor;
  primaryColor: $primaryColor;
  light: $light;
  dangerColor: $dangerColor;
}

/* Is Company */
.isCompany {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.isCompanyText {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  cursor: pointer;
}

/* Payment Mode */

.paymentModeContainer {
  width: 100%;
}

.paymentModeHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.paymentModeHeader > svg {
  height: 20px;
}

.paymentModeFee {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paymentModeTitle {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  font-weight: 600;
  flex-grow: 1;
  margin-left: 8px;
}

.paymentModeFeeAmount {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  margin-right: 4px;
}

/* Save Company */

.mandateAcceptance {
  font-family: $primaryFontFamily;
  font-size: 12px;
  color: $primaryFontColor;
  margin-top: 20px;
  text-align: center;
}

.spinner {
  border: 2px solid $primaryColor;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px !important;
  height: 16px !important;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// For legacy donations
.donationPaymentSection {
  min-height: 100vh;
  width: 100%;
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 0px;
  position: relative;
}

.leaderBoardBushImage,
.leaderBoardGroupTreeImage {
  display: none;
}
.leaderBoardBushImageMobile {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

@include smTabletView {
  .leaderBoardBushImageMobile {
    display: none;
  }
}
@include lgLaptopView {
  .leaderBoardBushImage {
    display: block;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  .leaderBoardGroupTreeImage {
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}

.paymentMethodsTabsContainer {
  display: flex;
  padding-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  // gap: 12px;
  // padding-bottom: 20px;
  > .paymentMethod {
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 12px;
    border: 1px solid $dividerColor;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 64px;
    height: 40px;
    color: $dividerColor;
    justify-content: center;
    position: relative;
    > label {
      margin-top: 6px;
      font-size: 10px;
    }
    &:hover {
      cursor: pointer;
      color: $primaryFontColor;
      background-color: $blueishGrey;
      border: 0.5px solid $blueishGrey;
    }
    .checkMark {
      display: none;
    }
  }
  .paymentMethodSelected {
    background-color: $blueishGrey;
    border: 0.5px solid $blueishGrey;
    color: $primaryFontColor;
    font-weight: 600;

    > .checkMark {
      display: block;
      position: absolute;
      right: -6px;
      top: -6px;
    }
  }
}

.contributionAmount {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  margin-top: 20px;
  text-align: center;
  font-style: italic;
}

.contributionMessage {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  margin-top: 20px;
  text-align: center;
}

.thankyouImageContainer {
  display: flex;
  justify-content: center;
}
.thankyouImage {
  background-image: url('/tenants/planet/images/donations/donationCompletion.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 280px;
  height: 280px;
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.tempThankYouImage {
  background-image: url('/tenants/planet/images/donations/donationCompletion.jpg');
  background-position: center;
  background-size: cover;
  width: 2300px;
  height: 2300px;
  align-self: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.tempDonationCount {
  font-size: 130px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 300px;
  padding: 0px 80px;
  color: $light;
  font-variant-ligatures: none;
}

.tempDonationTenant {
  font-size: 75px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  position: absolute;
  bottom: 60px;
  color: $light;
  font-variant-ligatures: none;
}

.donationCount {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  bottom: 20px;
  color: $light;
  font-variant-ligatures: none;
}

.donationTenant {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  text-align: center;
  margin-top: 16px;
  color: $light;
  font-variant-ligatures: none;
}

.pfpLogo {
  position: absolute;
  right: 12px;
  top: 12px;
  height: 36px;
  width: 36px;
  background-color: $light;
  border-radius: 36px;
  padding: 4px;
}

.shareRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -30px;
  width: fit-content;
  left: 50%;
  padding: 5px 10px;
  border-radius: 30px;
  transform: translate(-50%, 0%);
  background-color: $light;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
}

.shareIcon {
  padding: 4px 4px;
  // background-color: blue;
  border-radius: 30px;
  margin: 0px 5px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
  :hover {
    cursor: pointer;
  }
}
.downloadButton {
  border-radius: 40px;
  height: 48px;
  padding: 12px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: $primaryColor;
  color: $light;
  font-family: $primaryFontFamily;
  font-weight: 600;
  :hover {
    cursor: pointer;
  }
}

@include smTabletView {
  .speedDialButton:focus {
    outline: none;
  }
  .container {
    width: 412px;
  }
  .thankyouImage {
    width: 372px;
    height: 372px;
  }
}

:export {
  blueishGrey: '#f2f2f7';
}

.thankyouImageHeader {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: $light;
  font-variant-ligatures: none;
  padding-top: 15%;
  white-space: pre-line;
}

.thankyouImageHeader p h1 {
  font-size: 34px;
  font-weight: bolder;
}

.tempthankyouImageHeader {
  font-size: 100px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  padding-top: 17%;
  color: $light;
  font-variant-ligatures: none;
  white-space: pre-line;
}

.tempthankyouImageHeader p h1 {
  font-size: 150px;
}

.actionButtonsText {
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  color: $primaryFontColor;
  margin-left: 24px;
  margin-right: 24px;
}

.disclaimerContainer {
  display: flex;
  flex-direction: row;
  background-color: rgb(231, 237, 255);
  border-radius: 10px;
  width: 100%;
  padding: 18.5px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  > div {
    display: flex;
    justify-content: flex-start;
    width: 48px;
    height: 20px;
  }
  > p {
    margin-left: 12px;
  }
}

.showContactDetails {
  margin-left: 22px;
  margin-right: 22px;
  margin-top: -70px;
  color: $primaryFontColor;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;

  border-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 3px 6px -4px rgba(116, 116, 116, 0.7);
  .showContactDetailsName {
    font-size: 12px;
    font-weight: bold;
  }
  .showContactDetailsAddress {
    font-size: 12px;
    margin-top: 2px;
  }
}

.contributionMessageContainer {
  margin-left: 22px;
  margin-right: 22px;
  margin-top: -80px;
  color: $primaryFontColor;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 3px 6px -4px rgba(116, 116, 116, 0.7);
}
