@import '../../../theme/theme';

.mapContainer {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(70, 168, 221, 0.15);
  padding-bottom: 60px;
}

.mapContainer > div:first-child {
  height: 100% !important;
  width: 100% !important;
}

.marker {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 10%;
  background: $primaryColor;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 40%;
  margin: -20px 0 0 -20px;
  box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.markerType {
  transform: rotate(45deg);
  width: 60px;
  height: 40px;
  margin-left: -6px;
  margin-top: -4px;
}

.mapNavigation {
  position: absolute;
  right: 20px;
  bottom: 50px;
}

.projectControls {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  bottom: auto;
  top: 30px;
  width: max-content;
  height: 30px;
  padding: 5px 20px;
  background-color: #f5f5f5;
  border: 1px solid #aaaaaa;
  border-radius: 6px;
  cursor: pointer;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $primaryFontFamily;
  z-index: 10;
}

.projectControls > div > svg {
  height: 16px;
  padding: 0 8px;
}

.projectControlText {
  font-size: 14px;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  min-width: 100px;
}

.mapContainer .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  padding-left: 20px;
}

.projectSitesButton {
  position: fixed;
  right: 60px;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  line-height: 16px;
  cursor: pointer;
  z-index: 11;
  svg {
    width: 20px;
  }
}

.dropdownContainer {
  position: fixed;
  right: 60px;
  top: 20px;
}

.projectSitesDropdown {
  z-index: 9999;
}

.exploreButton {
  position: fixed;
  right: 10px;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  line-height: 16px;
  cursor: pointer;
  z-index: 11;
}
.exploreButton > svg {
  width: 17px;
}

.exploreText {
  padding-left: 4px;
  margin: 0;
}

.exploreCaption {
  border-top: 2px solid $dividerColor;
  padding-top: 15px;
  width: 200px;
  font-size: 10px;
  text-align: center;
}

.exploreInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 10px;
  cursor: pointer;
}

.deforestionSlider > div {
  border: none;
}

.deforestionSlider > div > div > ul > li > div > div > header {
  display: none !important;
}

.deforestionSlider > div > div {
  width: 100%;
}

.deforestionSlider > div > div > ul > li {
  width: 100%;
}

.deforestionSlider > div > div > ul > li > div {
  padding: 0px 4px 10px 4px !important;
}

.deforestionSlider > div > div > ul > li > div > div > div > div > button {
  background-color: transparent;
}

.infoModal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}

.infoExpanded {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 375px;
  max-width: 100%;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  animation: fade_in_show 0.5s;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.infoTitle {
  font-weight: 600;
  color: $primaryFontColor;
}

.infoContent {
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.infoContent > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
}
.infoContent > a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $primaryFontColor;
}

.infoContent > div > p {
  font-size: 10px;
}

.infoContent > a > svg {
  width: 20px;
  margin-right: 6px;
}

.infoContent > div > div {
  width: 100%;
}

.infoContent > div > div > ul > li {
  width: 100%;
}

.infoContent > div > div > ul > li > div {
  padding: 15px 4px !important;
}

.infoContent > div > div > ul > li > div > div > header {
  display: none !important;
}

.reforestationScale {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.reforestationScale > div {
  width: 230px !important;
  margin: 0 5px;
  height: 7px;
  border-radius: 50px;
  background: linear-gradient(
    90deg,
    rgba(126, 212, 230, 1) 20%,
    rgba(59, 0, 255, 1) 100%
  );
}

.reforestationScale > p:nth-child(1) {
  font-size: 12px;
  // color: #fadc2f;
}

.reforestationScale > p:nth-child(3) {
  font-size: 12px;
  // color: #fadc2f;
}

.currentForestScale {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.currentForestScale > div {
  width: 230px !important;
  margin: 0 5px;
  height: 7px;
  border-radius: 50px;
  background: linear-gradient(90deg, $primaryColor 1.1%, $primaryDarkColor 98.6%);
}

.currentForestScale > p:nth-child(1) {
  font-size: 12px;
  color: $primaryColor;
}

.currentForestScale > p:nth-child(3) {
  font-size: 12px;
  color: $primaryDarkColor;
}

.layerLegend {
  width: 200px;
}

.infoClose {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.infoClose svg {
  width: 20px;
}

.exploreExpanded {
  position: fixed;
  right: 70px;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  animation: fade_in_show 0.5s;
  z-index: 11;
}

@keyframes fade_in_show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.exploreToggleRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
}

.exploreToggleRow svg {
  width: 15px;
}

.exploreExpanded hr {
  margin-top: 10px;
}

.exploreNote {
  padding-top: 10px;
  text-align: center;
  width: 200px;
  font-family: $primaryFontFamily;
  font-size: 10px;
}

.exploreNote a {
  color: $primaryColor;
}

.exploreNote a:visited {
  color: $primaryColor;
}

.exploreNote a:hover {
  color: $primaryColor;
}

.lngSwitcher {
  position: absolute;
  bottom: 0px;
  right: 20px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  background-color: $light;
  padding: 6px 18px 4px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-family: $primaryFontFamily;
  font-weight: 400;
  z-index: 999;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: visible;
  > a {
    margin-left: 10px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.75);
  }
}

@include smTabletView {
  .projectSitesButton {
    top: 100px;
    right: 65px;
  }

  .dropdownContainer {
    top: 100px;
  }

  .exploreButton {
    top: 100px;
  }

  .exploreExpanded {
    top: 110px;
  }

  // .infoExpanded {
  //   top: 100px;
  // }
}

@include mdTabletView {
  .mapContainer {
    padding-top: 60px;
    padding-bottom: 0px;
  }

  .exploreExpanded {
    top: 110px;
  }
  .projectSitesButton {
    top: 110px;
    right: 70px;
  }

  .dropdownContainer {
    top: 110px;
    right: 70px;
  }

  .exploreButton {
    top: 110px;
    right: 20px;
    flex-direction: row;
  }
}

// @include lgLaptopView {
//   .projectControls {
//     left: 0px;
//   }
// }

@include xsPhoneView {
  .mapNavigation {
    display: none;
  }
  .projectControls {
    bottom: 80px;
  }
}

// Popover

.popover {
  position: relative;

  .popoverContent {
    position: absolute;
    z-index: 10;
    background-color: $light;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    display: none;
    flex-direction: column;
    font-weight: 400;
  }
  &:hover,
  &:active {
    cursor: pointer;
    .popoverContent {
      display: flex;
    }
  }
}

@include lgLaptopView {
  .projectSitesDropdown {
    right: 120px;
  }

  .projectControls {
    display: flex;
    top: auto;
    bottom: 30px;
    left: 350px;
  }
}
